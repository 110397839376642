import { assertGood } from 'lib/assertBoom'
import { debug, logWarn } from 'lib/logging'

// This function is designed to work on the client and server
export function getCookie<T>(name: string, request?: Request): T | undefined {
  assertGood(typeof window === `object` || request != null, `request object required if not calling from the client`)
  let cookie = typeof window === `object` ? window.document.cookie : request!.headers.get(`cookie`)
  if (!cookie) return undefined

  // nosemgrep
  let matches = cookie.match(new RegExp(`(?:^|; )` + name.replace(/([.$?*|{}()[]\\\/\+^])/g, `\\$1`) + `=([^;]*)`))
  try {
    if (matches) {
      debug(`cookie`, `matches`, name, matches[1])
      let val = JSON.parse(matches[1])
      debug(`cookie`, `parsed cookie`, name, val)
      return val as T
    } else {
      debug(`cookie`, `no cookie found`, name, cookie)
    }
  } catch (err) {
    logWarn(`couldnt parse cookie`, matches?.[1])
    return undefined
  }
}
