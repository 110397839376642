export let darkTheme = {
  white: `#0A0A0A`,
  black: `#FFFFFF`,

  'trn-950': `#FAFAFA`,
  'trn-900': `#F5F5F5`,
  'trn-800': `#EBEBEB`,
  'trn-700': `#CCCCCC`,
  'trn-600': `#999999`,
  'trn-500': `#808080`,
  'trn-400': `#666666`,
  'trn-300': `#4D4D4D`,
  'trn-200': `#333333`,
  'trn-100': `#212121`,
  'trn-50': `#212121`,

  'red-950': `#fef2f2`,
  'red-900': `#fee2e2`,
  'red-800': `#fecaca`,
  'red-700': `#fca5a5`,
  'red-600': `248 71 71`,
  'red-500': `239 68 68`,
  'red-400': `200 38 38`,
  'red-300': `#b91c1c`,
  'red-200': `#991b1b`,
  'red-100': `#7f1d1d`,
  'red-50': `#450a0a`,

  'amber-950': `#fffbeb`,
  'amber-900': `#fef3c7`,
  'amber-800': `#fde68a`,
  'amber-700': `#fcd34d`,
  'amber-600': `#fbbf24`,
  'amber-500': `#f59e0b`,
  'amber-400': `#d97706`,
  'amber-300': `#b45309`,
  'amber-200': `#92400e`,
  'amber-100': `#78350f`,
  'amber-50': `#451a03`,

  'green-950': `#f0fdf4`,
  'green-900': `#dcfce7`,
  'green-800': `#bbf7d0`,
  'green-700': `#86efac`,
  'green-600': `74 222 128`,
  'green-500': `34 197 94`,
  'green-400': `22 163 74`,
  'green-300': `#15803d`,
  'green-200': `#166534`,
  'green-100': `#14532d`,
  'green-50': `#052e16`,

  'gray-950': `#f9fafb`,
  'gray-900': `#f3f4f6`,
  'gray-800': `#e5e7eb`,
  'gray-700': `#d1d5db`,
  'gray-600': `#9ca3af`,
  'gray-500': `#6b7280`,
  'gray-400': `#4b5563`,
  'gray-300': `#374151`,
  'gray-200': `#1f2937`,
  'gray-100': `#111827`,
  'gray-50': `#030712`,

  'neutral-950': `#fafafa`,
  'neutral-900': `#f5f5f5`,
  'neutral-800': `#e5e5e5`,
  'neutral-700': `#d4d4d4`,
  'neutral-600': `#a3a3a3`,
  'neutral-500': `#737373`,
  'neutral-400': `#525252`,
  'neutral-300': `#404040`,
  'neutral-200': `#262626`,
  'neutral-100': `#171717`,
  'neutral-50': `#0a0a0a`,

  'blue-950': `#eff6ff`,
  'blue-900': `#dbeafe`,
  'blue-800': `#bfdbfe`,
  'blue-700': `#93c5fd`,
  'blue-600': `96 165 250`,
  'blue-500': `59 130 246`,
  'blue-400': `37 99 235`,
  'blue-300': `#1d4ed8`,
  'blue-200': `#1e40af`,
  'blue-100': `#1e3a8a`,
  'blue-50': `#172554`,

  'yellow-950': `#fefce8`,
  'yellow-900': `#fef9c3`,
  'yellow-800': `#fef08a`,
  'yellow-700': `#fde047`,
  'yellow-600': `#facc15`,
  'yellow-500': `#eab308`,
  'yellow-400': `#ca8a04`,
  'yellow-300': `#a16207`,
  'yellow-200': `#854d0e`,
  'yellow-100': `#713f12`,
  'yellow-50': `#422006`,
}
